var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-launches"},[(!_vm.headerContent)?_c('div',{staticClass:"header-spacing"}):_c('page-header',{attrs:{"image":_vm.headerImage,"lighter":""}},[_c('h5',[_vm._v(_vm._s(_vm.headerTags))]),_c('router-link',{staticClass:"text-light text-decoration-none",attrs:{"to":{
      name: 'content',
      params: {
        id: _vm.headerContent.id,
        slug: _vm.headerContent.slug
      }
    }}},[_c('h1',[_vm._v(_vm._s(_vm.headerContent.title))])]),_c('p',[_c('span',[_vm._v("Categoria: "),_c('strong',[_vm._v(_vm._s(_vm.headerCategory))])])]),_c('div',{staticClass:"header-buttons d-flex mb-3"},[_c('watch-button',{staticClass:"mr-2",attrs:{"content":_vm.headerContent}}),_c('div',{staticClass:"mr-2"},[_c('big-list-button',{attrs:{"content":_vm.headerContent}})],1)],1)],1),_c('div',{staticClass:"container-fluid py-5"},[_c('div',{staticClass:"container"},[_c('action-dispatcher',{attrs:{"action":"contents/getLaunches"}},[_c('carousel-wrapper',{attrs:{"items":_vm.launches,"title":"Mais recentes"}})],1),_c('action-dispatcher',{attrs:{"action":"contents/getWeekly"}},[_c('carousel-wrapper',{attrs:{"items":_vm.weekly,"title":"Estreias dessa semana"}})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }