<template>
  <div class="view-launches">
    <div v-if="!headerContent" class="header-spacing"></div>

    <page-header v-else :image="headerImage" lighter>
      <h5>{{ headerTags }}</h5>
      <router-link :to="{
        name: 'content',
        params: {
          id: headerContent.id,
          slug: headerContent.slug
        }
      }" class="text-light text-decoration-none">
        <h1>{{ headerContent.title }}</h1>
      </router-link>
      <p>
        <span>Categoria: <strong>{{ headerCategory }}</strong></span>
      </p>

      <div class="header-buttons d-flex mb-3">
        <watch-button class="mr-2" :content="headerContent" />
        <div class="mr-2">
          <big-list-button :content="headerContent" />
        </div>
      </div>
    </page-header>

    <div class="container-fluid py-5">
      <div class="container">
        <action-dispatcher action="contents/getLaunches">
          <carousel-wrapper :items="launches" title="Mais recentes" />
        </action-dispatcher>

        <action-dispatcher action="contents/getWeekly">
          <carousel-wrapper :items="weekly" title="Estreias dessa semana" />
        </action-dispatcher>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import CarouselWrapper from '@/components/CarouselWrapper'
import { mapState } from 'vuex'
import get from 'lodash/get'

export default {
  name: 'launches',
  components: {
    CarouselWrapper,
    PageHeader
  },
  computed: {
    ...mapState('contents', ['launches', 'weekly']),
    headerContent () {
      return get(this.launches, '[0]', null)
    },
    headerImage () {
      return get(get(this.headerContent, 'thumbnails', []).find(item => item.device === 'web'), 'file', '')
    },
    headerCategory () {
      return get(this.headerContent, 'categories[0].name', '')
    },
    headerTags () {
      return get(this.headerContent, 'tags', []).map(item => `#${item.name}`).join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.view-launches {
  .header-buttons {
    ::v-deep .like-button {
      font-size: 28px !important;
    }
  }

  h4 {
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
}
</style>
